import React, { useState, useEffect, useContext } from 'react';


import SimpleTable from '../../components/tables/simpleTable';

import TransferModal from './forms/transferModal';
import AddStockControl from './forms/addModal';
import RemoveStockControl from './forms/removeModal';

import SearchFilter from '../../components/filters/search';
import CustomButton from '../../components/buttons/customButton';
import IsActiveFilter from '../../components/filters/isActive';
import IsOrderFilter from '../../components/filters/isOrderFilter';
import AddButton from '../../components/buttons/addButton';
import DataFilter from '../../components/filters/dataFilter';
import DatePicker from '../../components/filters/datePicker';


import { FcKindle } from "react-icons/fc";

import Loader from '../../components/loader/loader';
import { numberFormatter, totalFormatter, decimalFormatter } from '../../components/formatters/numberFormatter';
import { dateFormatter } from '../../dynamic/dateFormatter';
import { typeFormatter, paymentTypeFormatter } from '../../components/formatters/defFormatter';
import { clientOrderColumnDefs } from '../../components/tables/columnDefs';
import { dateTableFormatter } from '../../dynamic/dateFormatter';
import toast from 'react-hot-toast';

import { Space, Input, Tag, Button, Tabs } from 'antd';

import Select from 'react-select';
import { Col, Form, Row, DropdownButton, Dropdown } from 'react-bootstrap';


import { FiEdit } from "react-icons/fi";
import { FcUndo, FcRefresh, FcFlowChart, FcRules } from "react-icons/fc";
import { BiSolidTrash, BiBarcodeReader } from "react-icons/bi";
import { FcPlus, FcMultipleInputs } from "react-icons/fc";
import { HiMinusCircle } from "react-icons/hi";

import { DataContext } from '../../init/getData';




function StockControl(props) {

  let { type } = props;


  const { api, userData, settingData, wareHouseData,  categoryData, refWareHouseData, deliveryData } = useContext(DataContext);
  const createdBy = parseInt(userData.id);
  const roundTo = settingData.roundTo;
  const userRole = userData.role;
  const defaultWareHouseId = userData.role.wareHouseSaleId;


  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [refreshTable, setRefreshTable] = useState(true);

  const [showTransferModal, setShowTransferModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  const table = 'stockControl';
  const title = 'Stock Control';


  const [idRow, setIdRow] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [filter, setFilter] = useState({ search: '', isActive: true, isStock: true, categoryId: null, wareHouseId: defaultWareHouseId });

    // Category Options
    const categoryOptions = categoryData.map(item => ({
      value: item.id,
      label: item.name,
    }));

    categoryOptions.push({ value: null, label: 'All Categories' })

  const openModal = (type, record) => {
    if (type == 'transfer') {  
      setShowTransferModal(!showTransferModal)
    } else
      if (type == 'add') {  
        setShowAddModal(!showAddModal)
      } else
        if (type == 'remove') {
          setShowRemoveModal(!showRemoveModal)
        }
    setIdRow(record)
  }

  //Get Data 
  useEffect(() => {
    const fetchData = async () => {
      try {
        await refWareHouseData();
        setIsLoading(false);
      } catch (error) {
        console.error('Error refreshing data Contact Support');
      }
    };
    fetchData();
  }, []);


  const refreshTableFunction = () => {
    setRefreshTable(!refreshTable)
  }



  // WareHouse Options
  const wareHouseOptions = wareHouseData?.map(item => ({
    value: item.id,
    label: item.name,
  }))

  const selectedWareHouse = wareHouseOptions.find(option => option.value === filter.wareHouseId);

  const reStock = async () => {
    try {
      setIsLoading(true);
      const data = {
        wareHouseId: filter.wareHouseId,
      }
      let res = await api.post(`stockValue/reStockWareHouse`, data);

      if (res.data.status) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
      setIsLoading(false);
    } catch (error) {
      toast.error("Failed Contact Support");
      setIsLoading(false);
    }
  }

  //Excel Data
  const getExcelData = (data, getSummary) => {

    const transformedData = [];
    data.forEach(item => {

      const newItem = {
        SoldAt: item.transaction?.createdAt,
        barcode: item.product?.barcode,
        name: item.product?.name,
        serial: item.serial,
        customer: item.transaction?.account?.name,
        transactionId: item.transactionId,
      };

      transformedData.push(newItem);
    });

    const summary = {};

    return { transformedData, summary };
  };


  let newColumnDefs = [
    { title: 'Barcode', dataIndex: 'barcode' },
    { title: 'Name', dataIndex: 'name' },
    { title: 'Stock', dataIndex: 'stocks', render: (stocks) => numberFormatter(stocks[0]?.stock ? stocks[0]?.stock : 0, 2) },
    { title: 'Cost', dataIndex: 'cost' , render: numberFormatter},
    { title: 'Price', dataIndex: 'price', render: numberFormatter },
    { title: 'Type', dataIndex: 'type' },
  ];

  useEffect(() => {

    newColumnDefs.push(
      {
        title: 'Transfer',
        width: '5%',
        render: (_, record) => (
          <FcMultipleInputs size={20} style={{ cursor: 'pointer' }} onClick={() => openModal('transfer', record)} />
        ),
      },
      {
        title: 'Add',
        width: '5%',
        render: (_, record) => (
          <FcPlus size={20} style={{ cursor: 'pointer' }} onClick={() => openModal('add', record)} />
        ),
      },
      {
        title: 'Remove',
        width: '5%',
        render: (_, record) => (
          <HiMinusCircle size={20} style={{ cursor: 'pointer' }} onClick={() => openModal('remove', record)} />
        ),
      });

    setColumnDefs(newColumnDefs);

  }, []);


  const formView = '';


  const tableFooter = (

    <div style={{ display: 'flex', justifyContent: 'space-between', position: 'absolute', left: '45%', transform: 'translateX(-50%)' }}>

    </div>

  );

  const modalFooter = (
    <div style={{ textAllign: 'center' }}>

      {/* Total */}
      <Row className="mb-3" style={{ marginTop: '2%', justifyContent: 'center' }}>
        <Form.Group as={Col} style={{ display: 'flex', justifyContent: 'center' }}>
          <Form.Label></Form.Label>

        </Form.Group>
      </Row>
    </div>
  )

  return (
    <div className='card'>

      {isLoading &&
        <Loader />
      }

      <TransferModal
        showModal={showTransferModal}
        toggleModal={(e) => openModal('transfer', [])}
        refreshTable={refreshTableFunction}
        selectedWareHouse={selectedWareHouse}
        idRow={idRow}
        wareHouseOptions={wareHouseOptions}
      />

      <AddStockControl
        showModal={showAddModal}
        toggleModal={(e) => openModal('add', [])}
        refreshTable={refreshTableFunction}
        wareHouseId={filter.wareHouseId}
        idRow={idRow}
        wareHouseOptions={wareHouseOptions}
      />

      <RemoveStockControl
        showModal={showRemoveModal}
        toggleModal={(e) => openModal('remove', [])}
        refreshTable={refreshTableFunction}
        wareHouseId={filter.wareHouseId}
        idRow={idRow}
        wareHouseOptions={wareHouseOptions}
      />




      {!isLoading &&
        <>
          <div>

            <SearchFilter setFilter={setFilter} />

            <DataFilter selectedOption={selectedWareHouse}
              setFilter={setFilter} dataOptions={wareHouseOptions}
              filterBy={'wareHouseId'} placeHolder={'All WareHouse'} />

            <DataFilter setFilter={setFilter} dataOptions={categoryOptions} 
            filterBy={'categoryId'} placeHolder={'All Categories'} />


          </div>



          <SimpleTable
            filter={filter}
            table={table}
            title={title}
            columnDefs={columnDefs}
            refreshTable={refreshTable}
            idRow={idRow}
            setIdRow={setIdRow}
            data={data}
            setData={setData}
            tableFooter={tableFooter}
            api={api}
            getExcelData={getExcelData}
          />



        </>
      }

    </div>
  )
}

export default StockControl;
