import React, { useState, useEffect, useContext } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Switch, Collapse } from 'antd';

import Select from 'react-select';

import { DataContext } from '../init/getData';
const { Panel } = Collapse;



function GeneralSetting() {

  const { api, userData, refreshSetting, settingData } = useContext(DataContext);
  const userId = userData.id;
  const userName = userData.name;
  const userRole = userData.role.name;


  const [rate, setRate] = useState([]);
  const [tva, setTva] = useState('');
  const [currency, setCurrency] = useState({ value: '', label: '' });
  const [ratedCurrency, setRatedCurrency] = useState({ value: '', label: '' });
  const [rateMultiply, setRateMultiply] = useState('');
  const [showRateOnInvoice, setShowRateOnInvoice] = useState('');

  const [showPriceBeForeDiscount, setShowPriceBeForeDiscount] = useState('');
  const [showWareHouseInSales, setShowWareHouseInSales] = useState('');

  const [loadingEffect, setLoadingEffect] = useState(false);


  const [costChangerMethod, setCostChangerMethod] = useState('');

  const [isRated, setIsRated] = useState(false);
  const [defaultTva, setDefaultTva] = useState(false);
  const [language, setLanguage] = useState('');
  const [moneyExchange, setMoneyExchange] = useState(false);
  const [printDirect, setPrintDirect] = useState(false);
  const [showZeroStock, setShowZeroStock] = useState(false);

  const [closingHour, setClosingHour] = useState('');
  const [defaultDate, setdefaultDate] = useState('');

  const [lowInStock, setLowInStock] = useState('');

  useEffect(() => {
  }, [settingData]);

  useEffect(() => {
    if (settingData) {
      setLanguage(settingData.language);
      setRate(settingData.rate);
      setTva(settingData.tva);
      setDefaultTva(settingData.defaultTva)
      setClosingHour(settingData.closingHour);
      setdefaultDate(settingData.defaultDate);
      setLowInStock(settingData.lowInStock);
      setCostChangerMethod(settingData.costChangerMethod)
      setMoneyExchange(settingData.moneyExchange)
      setPrintDirect(settingData.printDirect)
      setShowZeroStock(settingData.showZeroStock)

      setIsRated(settingData.isRated)
      setRateMultiply(settingData.rateMultiply)
      setRateMultiply(settingData.rateMultiply)
      setShowRateOnInvoice(settingData.showRateOnInvoice)

      setShowPriceBeForeDiscount(settingData.showPriceBeForeDiscount)
      setShowWareHouseInSales(settingData.showWareHouseInSales)

      setCurrency(currencyOptions.find(option => option.value === settingData.currency))
      setRatedCurrency(currencyOptions.find(option => option.value === settingData.ratedCurrency))
    }
  }, [settingData]);


  const handleSubmit = async (e) => {
    e.preventDefault();

    let data = {
      language: language,
      rate: rate,
      tva: tva,
      currency: currency.value,
      closingHour: closingHour,
      defaultDate: defaultDate,
      isRated: isRated,
      ratedCurrency: ratedCurrency.value,
      defaultTva: defaultTva,
      lowInStock: lowInStock,
      costChangerMethod: costChangerMethod,
      moneyExchange: moneyExchange,
      printDirect: printDirect,
      showZeroStock: showZeroStock,
      rateMultiply: rateMultiply,
      showRateOnInvoice: showRateOnInvoice,
      showPriceBeForeDiscount: showPriceBeForeDiscount,
      showWareHouseInSales: showWareHouseInSales,
    }

    let res = await api.post(`/setting/edit`, JSON.stringify(data))
    if (res.data.status) {
      toast.success(res.data.message);
      refreshSetting();
    }
    else {
      toast.error(res.data.message);
    }

  }



  const currencyOptions = [
    { value: '$', label: 'United States Dollar ($)' },
    { value: '€', label: 'Euro (€)' },
    { value: '¥', label: 'Japanese Yen (¥)' },
    { value: '£', label: 'British Pound Sterling (£)' },
    { value: 'A$', label: 'Australian Dollar (A$)' },
    { value: 'C$', label: 'Canadian Dollar (C$)' },
    { value: 'CHF', label: 'Swiss Franc (CHF)' },
    { value: '¥', label: 'Chinese Yuan (¥)' },
    { value: 'kr', label: 'Swedish Krona (kr)' },
    { value: 'NZ$', label: 'New Zealand Dollar (NZ$)' },
    { value: 'LL', label: 'Lebanese Lira (LL)' },
    { value: 'AED', label: 'United Arab Emirates Dirham (AED)' },
    { value: 'CFA', label: 'CFA Franc (CFA)' },
    { value: '₹', label: 'Indian Rupee (₹)' },
    { value: '₽', label: 'Russian Ruble (₽)' },
    { value: 'R$', label: 'Brazilian Real (R$)' },
    { value: 'R', label: 'South African Rand (R)' },
    { value: 'MX$', label: 'Mexican Peso (MX$)' },
    { value: 'S$', label: 'Singapore Dollar (S$)' },
    { value: 'HK$', label: 'Hong Kong Dollar (HK$)' },
    { value: '₩', label: 'South Korean Won (₩)' },
    { value: '₺', label: 'Turkish Lira (₺)' },
    { value: 'kr', label: 'Norwegian Krone (kr)' },
    { value: 'zł', label: 'Polish Zloty (zł)' },
    { value: 'AR$', label: 'Argentine Peso (AR$)' },
    { value: '฿', label: 'Thai Baht (฿)' },
    { value: '₫', label: 'Vietnamese Dong (₫)' },
    { value: '₨', label: 'Pakistani Rupee (₨)' },
    { value: '₪', label: 'Israeli Shekel (₪)' },
    { value: 'E£', label: 'Egyptian Pound (E£)' },
    { value: 'SAR', label: 'Saudi Riyal (SAR)' },
    { value: 'RM', label: 'Malaysian Ringgit (RM)' },
    { value: 'Rp', label: 'Indonesian Rupiah (Rp)' },
    { value: 'kr', label: 'Danish Krone (kr)' },
    { value: '₱', label: 'Philippine Peso (₱)' },
    { value: 'NT$', label: 'Taiwan Dollar (NT$)' },
    { value: 'Ft', label: 'Hungarian Forint (Ft)' },
    { value: 'Kč', label: 'Czech Koruna (Kč)' },
    { value: '₦', label: 'Nigerian Naira (₦)' },
    { value: '৳', label: 'Bangladeshi Taka (৳)' },
    { value: '₴', label: 'Ukrainian Hryvnia (₴)' },
    { value: 'MAD', label: 'Moroccan Dirham (MAD)' },
    { value: 'KSh', label: 'Kenyan Shilling (KSh)' },
    { value: 'GH₵', label: 'Ghanaian Cedi (GH₵)' },
    { value: 'QAR', label: 'Qatari Riyal (QAR)' },
    { value: 'GNF', label: 'Guinean Franc (GNF)' }
  ];




  const TypesForm = (
    <>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={3}>Closing Hour</Form.Label>
        <Col sm={9}>
          <div>
            <Form.Control value={closingHour} onChange={(e) => setClosingHour(e.target.value)} type="number" step='any' placeholder="hours after 12" style={{ width: '70%' }} />
          </div>
        </Col>
      </Form.Group>


      {/*  Default Date */}
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
        <Form.Label column sm={3}>
          Default Date
        </Form.Label>
        <Col sm={9}>
          <select className="form-control" style={{ width: '70%' }} value={defaultDate} onChange={(e) => setdefaultDate(e.target.value)}>
            <option value='daily'> Daily </option>
            <option value='monthly'> Monthly </option>
            <option value='yearly'> Yearly </option>
          </select>
        </Col>
      </Form.Group>


      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={3}>Low In Stock</Form.Label>
        <Col sm={9}>
          <div>
            <Form.Control value={lowInStock} onChange={(e) => setLowInStock(e.target.value)} type="number" step='any' placeholder="notification#" style={{ width: '70%' }} />
          </div>
        </Col>
      </Form.Group>


      {/*  Show Zero Stock */}
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={3}>Show Zero Stock</Form.Label>
        <Col sm={9}>
          <Switch defaultChecked onChange={() => setShowZeroStock(!showZeroStock)} checked={showZeroStock} />
        </Col>
      </Form.Group>


      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={3}>TVA %</Form.Label>
        <Col sm={9}>
          <Form.Control value={tva} onChange={(e) => setTva(e.target.value)} type="number" step='any' placeholder="%" style={{ width: '70%' }} />
        </Col>
      </Form.Group>

      {/* default Tva */}
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={3}>Default Use TVA</Form.Label>
        <Col sm={9}>
          <Switch defaultChecked onChange={() => setDefaultTva(!defaultTva)} checked={defaultTva} />
        </Col>
      </Form.Group>


      {/* Print Direct */}
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={3}>Print Direct</Form.Label>
        <Col sm={9}>
          <Switch defaultChecked onChange={() => setPrintDirect(!printDirect)} checked={printDirect} />
        </Col>
      </Form.Group>

      {/* language */}
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
        <Form.Label column sm={3}>
          language
        </Form.Label>
        <Col sm={9}>
          <select className="form-control" style={{ width: '70%' }} value={language} onChange={(e) => setLanguage(e.target.value)}>
            <option value={'english'}>English</option>
            <option value={'arabic'}>Arabic</option>
          </select>
        </Col>
      </Form.Group>

      {/* Cost Changer Method */}
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
        <Form.Label column sm={3}>
          Cost Changer Method
        </Form.Label>
        <Col sm={9}>
          <select className="form-control" style={{ width: '70%' }} value={costChangerMethod} onChange={(e) => setCostChangerMethod(e.target.value)}>
            <option value='StockValue'>Stock Value</option>
            <option value='AverageCost'>Average Cost</option>
          </select>
        </Col>
      </Form.Group>


      {/* Money Exchange */}
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={3}>Money Exchange</Form.Label>
        <Col sm={9}>
          <Switch defaultChecked onChange={() => setMoneyExchange(!moneyExchange)} checked={moneyExchange} />
        </Col>
      </Form.Group>

      {/* Currency */}
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={3}>Currency</Form.Label>
        <Col sm={9}>
          <Select
            className='dropDownSettings'
            options={currencyOptions}
            isSearchable={true}
            placeholder="Select"
            value={currency}
            onChange={setCurrency}
          />
        </Col>
      </Form.Group>



      {/* is Rated */}
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={3}>isRated</Form.Label>
        <Col sm={9}>
          <Switch defaultChecked onChange={() => setIsRated(!isRated)} checked={isRated} />
        </Col>
      </Form.Group>


      {/* Rate */}

      <div>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={3}>Rate</Form.Label>
          <Col sm={9}>
            <Form.Control value={rate} onChange={(e) => setRate(e.target.value)} type="number" step='any' placeholder="Rate" style={{ width: '70%' }} />
          </Col>
        </Form.Group>


        {/* Rating Formula */}
        <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
          <Form.Label column sm={3}>
            Rating Formula
          </Form.Label>
          <Col sm={9}>
            <select className="form-control" style={{ width: '70%' }} value={rateMultiply} onChange={(e) => setRateMultiply(e.target.value == 'true')}>
              <option value={'true'}>Multiply</option>
              <option value={'false'}>Divide</option>
            </select>
          </Col>
        </Form.Group>


        {/* Rated Currency */}
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={3}>Rated Currency</Form.Label>
          <Col sm={9}>
            <Select
              className='dropDownSettings'
              options={currencyOptions}
              isSearchable={true}
              placeholder="Select"
              value={ratedCurrency}
              onChange={setRatedCurrency}
            />
          </Col>
        </Form.Group>
      </div>


      {/* is Rated */}
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={3}>Show rate on invoice</Form.Label>
        <Col sm={9}>
          <Switch defaultChecked onChange={() => setShowRateOnInvoice(!showRateOnInvoice)} checked={showRateOnInvoice} />
        </Col>
      </Form.Group>


      {/* Show Price BeFore Discount */}
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={3}>Show Price BeFore Discount</Form.Label>
        <Col sm={9}>
          <Switch defaultChecked onChange={() => setShowPriceBeForeDiscount(!showPriceBeForeDiscount)} checked={showPriceBeForeDiscount} />
        </Col>
      </Form.Group>


      {/* Show WareHouse In Sales */}
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={3}>Show WareHouse In Sales</Form.Label>
        <Col sm={9}>
          <Switch defaultChecked onChange={() => setShowWareHouseInSales(!showWareHouseInSales)} checked={showWareHouseInSales} />
        </Col>
      </Form.Group>



    </>
  )


  const items = [
    {
      key: '1',
      label: 'Types',
      children: <>{TypesForm}</>,
    }

  ];

  return (
    <div
      className="card" style={{ userSelect: 'none', height: '86vh', display: 'flex', flexDirection: 'row', overflowY: 'scroll' }}
    >
      <Collapse className="custom-collapse" style={{ width: '100%' }} defaultActiveKey={['1']}>
        {items.map(item => (
          <Panel header={item.label} key={item.key} style={{ backgroundColor: '#d8e1e8' }}>
            {item.children}
          </Panel>
        ))}
      </Collapse>

      <Button type="primary" size="large" loading={loadingEffect} onClick={handleSubmit}

        style={{ marginLeft: '10px', backgroundColor: '#1d7790', height: '40px' }}>Save</Button>


    </div>
  );
}

export default GeneralSetting;
