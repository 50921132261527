import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';


const MultiplePrice = (props) => {

  const { showModal, toggleModal, addToCart, modelData } = props;

  const product = modelData?.product;
  const quantity = modelData?.quantity;
  const prices = product?.prices;

  const handleSubmit = async (selectedPrice) => {
    product.price = selectedPrice;
    addToCart(product, quantity);
    toggleModal();
  }


  return (
    <Modal show={showModal} onHide={toggleModal} >
      <Modal.Header style={{ background: "#1f2937" }}>
        <Modal.Title style={{ color: "white" }}>Select Price</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form id='addForm'>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(4, 1fr)',
              gap: '20px',
            }}
          >
            {prices && prices?.map((item, index) => (
              <div
                key={index}
                onClick={() => handleSubmit(item.price)}
                style={{
                  width: '100px',
                  height: '100px',
                  border: '0.1px solid #000',
                  borderRadius: '5px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#b6e3f4',
                  cursor: 'pointer',
                }}
              >
                <div style={{ fontWeight: 'bold' }}>{item.name}</div>
                <div>${item.price}</div>
              </div>
            ))}
          </div>



          <div className="modal-footer" style={{marginTop: '20px'}}>
            <button type="button" className="btn btn-secondary" onClick={toggleModal}>
              Close
            </button>
          </div>

        </form>
      </Modal.Body>
    </Modal>
  )
}


export default MultiplePrice;