import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import Select from 'react-select';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import { print } from "../../dynamic/print";
import { roundedNumberFormatter } from '../../components/formatters/numberFormatter';



import { FcPlus } from "react-icons/fc";
import { BiEdit } from "react-icons/bi";

import { DataContext } from '../../init/getData';
import { numberFormatter } from '../../dynamic/numberFormatter';

const Exchange = (props) => {

  const { userData, settingData, serverApi, localApi, devData } = useContext(DataContext);
  const userId = userData.id;
  const userName = userData.name;
  const userRole = userData.role.name;
  const wareHouseSaleId = userData.role.wareHouseSaleId;


  let api;
  if (!devData?.isServer && !devData?.isCloud) {
    api = serverApi;
  } else {
    api = localApi;
  }


  let { finalNetTotal } = props;

  const [paid, setPaid] = useState(0)
  const [paidRated, setPaidRated] = useState(0)

  const [allPaid, setAllPaid] = useState(0)

  const [returned, setReturned] = useState(0)
  const [returnedRated, setReturnedRated] = useState(0)

  const [remainig, setRemainig] = useState(0)
  const [remainigRated, setRemainigRated] = useState(0)

  useEffect(() => {

  }, []);

  const changePaid = (e) => {

    setPaid(e);
    const paid = parseFloat(e) || 0;

    const allReturned = parseFloat(returned) + parseFloat(returnedRated / settingData.rate);
    const allPaid = parseFloat(paid) + parseFloat(paidRated / settingData.rate);
    const allremainig = (finalNetTotal - allPaid) + parseFloat(allReturned);

    setAllPaid(allPaid);
    setRemainig(allremainig)
    setRemainigRated(allremainig * settingData.rate)
  }


  const changePaidRated = (e) => {
    setPaidRated(e);
    const paidRated = parseFloat(e) || 0;

    const allReturned = parseFloat(returned) + parseFloat(returnedRated / settingData.rate);
    const allPaid = parseFloat(paid) + parseFloat(paidRated / settingData.rate);
    const allremainig = (finalNetTotal - allPaid) + parseFloat(allReturned);

    setAllPaid(allPaid);
    setRemainig(allremainig)
    setRemainigRated(allremainig * settingData.rate)
  }



  const changeReturned = (e) => {
    setReturned(e);
    const returned = parseFloat(e) || 0;

    const allReturned = parseFloat(returned) + parseFloat(returnedRated / settingData.rate);
    const allPaid = parseFloat(paid) + parseFloat(paidRated / settingData.rate);
    const allremainig = (finalNetTotal - allPaid) + parseFloat(allReturned);

    setAllPaid(allPaid);
    setRemainig(allremainig)
    setRemainigRated(allremainig * settingData.rate)
  }

  const changeReturnedRated = (e) => {
    setReturnedRated(e);
    const returnedRated = parseFloat(e) || 0;

    const allReturned = parseFloat(returned) + parseFloat(returnedRated / settingData.rate);
    const allPaid = parseFloat(paid) + parseFloat(paidRated / settingData.rate);
    const allremainig = (finalNetTotal - allPaid) + parseFloat(allReturned);

    setAllPaid(allPaid);
    setRemainig(allremainig)
    setRemainigRated(allremainig * settingData.rate)
  }



  return (
    <div style={{ marginTop: '3%', textAlign: 'center' }}>

      <Row className="mb-3 justify-content-center" style={{ marginTop: '2%' }}>

        <Form.Group as={Col} md={3}>
          <Form.Label>Paid {settingData.currency}</Form.Label>
          <input style={{ textAlign: 'center' }} className="form-control" type='number' value={paid} onChange={(e) => changePaid(e.target.value)} ></input>
        </Form.Group>

        {(settingData.isRated || settingData.showRateOnInvoice) &&
          <Form.Group as={Col} md={3}>
            <Form.Label>Paid {settingData.ratedCurrency}</Form.Label>
            <input style={{ textAlign: 'center' }} className="form-control" type='number' value={paidRated} onChange={(e) => changePaidRated(e.target.value)} ></input>
          </Form.Group>
        }

      </Row>



      <Row className="mb-3 justify-content-center" style={{ marginTop: '2%' }}>

        <Form.Group as={Col} md={3}>
          <Form.Label>Return {settingData.currency}</Form.Label>
          <input style={{ textAlign: 'center' }} className="form-control" type='number' value={returned} onChange={(e) => changeReturned(e.target.value)} ></input>
        </Form.Group>

        {(settingData.isRated || settingData.showRateOnInvoice) &&
          <Form.Group as={Col} md={3}>
            <Form.Label>Return {settingData.ratedCurrency}</Form.Label>
            <input style={{ textAlign: 'center' }} className="form-control" type='number' value={returnedRated} onChange={(e) => changeReturnedRated(e.target.value)}></input>
          </Form.Group>
        }

      </Row>


      <Row className="mb-3 justify-content-center" style={{ marginTop: '2%' }}>

        <Form.Group as={Col} md={3}>
          <Form.Label>Remainig {settingData.currency}</Form.Label>
          <input style={{ textAlign: 'center' }} className="form-control" type='text' value={numberFormatter(remainig, 2)} disabled></input>
        </Form.Group>

        {(settingData.isRated || settingData.showRateOnInvoice) &&
          <Form.Group as={Col} md={3}>
            <Form.Label>Remainig  {settingData.ratedCurrency}</Form.Label>
            <input style={{ textAlign: 'center' }} className="form-control" type='text' value={roundedNumberFormatter(remainigRated, 2)} disabled></input>
          </Form.Group>
        }

      </Row>

    </div>

  )
}


export default Exchange;